.protected-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Hauteur totale de l'écran */
    background-color: #f4f4f4; /* Optionnel : arrière-plan léger */
  }
  
  .protected-form {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .protected-form h1 {
    margin-bottom: 1rem;
  }
  
  .protected-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .protected-form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .protected-form button:hover {
    background-color: #0056b3;
  }
  